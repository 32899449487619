<template>
    <a class="btn-smile2" href="javascript:void(0)" data-opendropdown="dropdown-emoticons">Smiley</a>
    <ul class="emoticons-menu emoticons hide" id="dropdown-emoticons">
        <li v-for="(emoji, index) in emojis" v-bind:key="index">
            <a :data-rel="emoji"><img class="emoji" width="20" height="20" align="absmiddle"
                    :src="'/global/emoji/images/emojis/' + emoji + '.png'" :alt="emoji" :title="emoji"></a>
        </li>
    </ul>
</template>
    
<script>
export default {
    name: 'EmojiDropdown',
    setup() {
        const emojis = ['blush', 'angel', 'angry', 'astonished', 'disappointed', 'cry', 'dizzy_face', 'fearful', 'flushed', 'grin', 'heart_eyes', 'innocent', 'joy', 'kissing_face', 'kissing_heart', 'laughing', 'no_mouth', 'pensive', 'relaxed', 'relieved', 'sleepy', 'smile', 'smiley', 'smirk', 'sob', 'sunglasses', 'sweat_smile', 'sweat', 'tongue', 'unamused', 'wink', 'yum', 'couple_with_heart', 'expressionless', 'eyebrow_raised', 'monocle', 'star-eyes', 'middlefinger', 'thermometer', 'serious_face', 'drooling', 'finger_covering_lips', 'hand_covering_mouth', 'sleeping', 'zipper_mouth', 'rolling_eyes', 'lying_face', 'thinking', 'thumbsup', 'thumbsdown', 'ok_hand', 'pray', 'kiss', 'eyes', 'feet', 'ghost', 'bouquet', 'blue_heart', 'broken_heart', 'love_letter', 'cupid', 'gift_heart', 'green_heart', 'heart', 'heartpulse', 'couple_with_heart', 'cold_sweat', 'dancer', 'blue_car', 'cocktail', 'coffee', 'tada', 'sweat_drops', 'tongue2', 'fire', 'crown', 'ring', 'eggplant', 'speak_no_evil', 'collision', 'smiling_imp', 'wave', 'peach', 'doughnut', 'banana', 'paw_prints', 'x', 'dog', 'cat2', 'cat', 'point_left', 'wink2', 'wine_glass', 'beer', 'persevere', 'melon', 'cherries', 'cherry_blossom', '100', 'exploding_head', 'facepalming', 'pleading_face', 'rainbow', 'upside_down_face', 'white_flag'];

        return {
            emojis
        };
    },
};
</script>
    