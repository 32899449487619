<template>
  <div>
    <div class="navMenu-main">
      <div id="menu" class="gn-icon-menu"><span></span></div>
    </div>
    <Header :instanceInfo="instanceInfo" />
    <div class="main">
      <div class="container">
        <div class="row">
          <SideMenu :instanceInfo="instanceInfo" />
          <div class="col-md-9">
            <h2 class="pink">{{ member?.name }} <span class="yr-loc"> &nbsp;
                | &nbsp; {{ member?.age }} years
                &nbsp;
                | &nbsp; {{ member?.country }}</span></h2>
            <p></p>
            <div class="row">
              <div v-if="member" class="col-md-4">
                <p>
                  <a :href="'/uploads/' + member?.image" data-lightbox="photo-collection">
                    <img :src="'/uploads/' + member?.image" alt="" class="rounded2">
                  </a>
                </p>
                <p class="small-margin">
                  <a v-if="member?.wish" href="#" class="btn btn-wishlist" @click="removeFromWishes">Remove from wish list</a>
                  <a v-else href="#" class="btn btn-wishlist"  @click="addToWishes">Add to wish list</a>
                </p>
              </div>

              <div class="col-md-8">


                <div class="row">
                  <div class="col-md-5" style="margin-bottom:20px;">
                  </div>

                  <div class="col-md-7">
                    <div class="flirtbox">
                      <form accept-charset="UTF-8" id="addFlirt" class="message" @submit.prevent="submitFlirt">
                        <ul class="splink hideMobile">
                          <li><a href="javascript:void(0);" class="send_flirt" data-flirts="desktop"><i class="love"></i>
                              Select flirt</a></li>
                          <li class="flirts-dropdown">
                            <div class="flirts bordered" style="display: none;" data-flirts="desktop">
                              <ul class="splink emoticons flirts" data-blink="">
                                <li data-action="blink">
                                  <a href="javascript:void(0);">
                                    <i class="nice"></i>
                                    <span class="message">You're bloody hot</span>
                                  </a>
                                  <input v-model="flirtCode" class="hide" type="radio" name="flirt" value="nice">
                                </li>
                                <li data-action="blink">
                                  <a href="javascript:void(0);">
                                    <i class="rose"></i>
                                    <span class="message">Marry me!</span>
                                  </a>
                                  <input v-model="flirtCode" class="hide" type="radio" name="flirt" value="rose">
                                </li>
                                <li data-action="blink">
                                  <a href="javascript:void(0);">
                                    <i class="like"></i>
                                    <span class="message">Let's cuddle!</span>
                                  </a>
                                  <input v-model="flirtCode" class="hide" type="radio" name="flirt" value="like">
                                </li>
                                <li data-action="blink">
                                  <a href="javascript:void(0);">
                                    <i class="kiss"></i>
                                    <span class="message">I live nearby, fancy a date?</span>
                                  </a>
                                  <input v-model="flirtCode" class="hide" type="radio" name="flirt" value="kiss">
                                </li>
                                <li data-action="blink">
                                  <a href="javascript:void(0);">
                                    <i class="hug"></i>
                                    <span class="message">Let's get naughty</span>
                                  </a>
                                  <input v-model="flirtCode" class="hide" type="radio" name="flirt" value="hug">
                                </li>
                                <li data-action="blink">
                                  <a href="javascript:void(0);">
                                    <i class="spank"></i>
                                    <span class="message">Fancy a fuck?</span>
                                  </a>
                                  <input v-model="flirtCode" class="hide" type="radio" name="flirt" value="spank">
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                        <input class="btn" type="submit" value="Send">
                      </form>
                    </div>
                  </div>
                </div>

                <form accept-charset="UTF-8" class="profilepage message" @submit.prevent="submitMessage">
                  <div class="txtarea2 clearfix">
                    <textarea v-model="messageBody" maxlength="700" placeholder="Write a message..." data-field="message" style="resize: none;"
                      class="" name="message" cols="50" rows="10"></textarea>
                    <ul>
                      <li>
                        <div class="file-up2">
                          <input class="file" id="image" name="image" type="file" accept=".gif,.jpg,.jpeg,.png">
                          <a class="btn-addphoto2" href="javascript:void(0)"><i class="camera"></i> Photo</a>
                        </div>
                      </li>
                      <li>
                        <EmojiDropdown />
                      </li>
                      <li><input type="submit" class="btn" value="Send"></li>
                    </ul>
                  </div>
                </form>

                <div v-if="submitSuccessful" class="alert alert-success alert-dismissible">
                    <button @click="clearSuccessMessage" type="button" class="close" data-dismiss="alert"
                        aria-label="Close"><span aria-hidden="true">×</span></button>
                    <p>Your message was successfylly sent!</p>
                </div>
                <div v-if="submitFailed" class="alert alert-danger alert-dismissible">
                    <button @click="clearFailureMessage" type="button" class="close" data-dismiss="alert"
                        aria-label="Close"><span aria-hidden="true">×</span></button>
                    <p>Your message couldn't be delivered. Check your credits and try again later or contact our support!</p>
                </div>
              <dl class="desc-list clearfix">
                <dt>Age:</dt>
                <dd>{{ member?.age }} years old</dd>
                <dt>Height:</dt>
                <dd>{{ member?.height }}</dd>
                <dt>Body type:</dt>
                <dd>{{ member?.bodyType }}</dd>
                <dt>Marital status:</dt>
                <dd>{{ member?.maritalStatus }}</dd>
                <dt>Hair Colour:</dt>
                <dd>{{ member?.hairColour }}</dd>
                <dt>Eye Colour:</dt>
                <dd>{{ member?.eyeColour }}</dd>
                <dt>Smoker:</dt>
                <dd>{{ member?.smoker ? 'Yes' : 'No' }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
</div>
</template>

<script>
import { ref } from 'vue';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';
import SideMenu from '@/app/components/SideMenu';
import EmojiDropdown from '@/app/components/EmojiDropdown';
import { fetchMember, removeFromWishlist, addToWishlist } from '@/app/api/member';
import { useUrl } from '@/app/router';
import { sendMessage } from '@/app/api/message';
import { useUser } from '@/app/use/user';


export default {
  name: 'Members',
  components: { Header, Footer, SideMenu, EmojiDropdown },
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
    companyName: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { name } = useUrl();
    const member = ref(null);

    const { updateCredits } = useUser();

    const loadMember = () => {
    fetchMember(name.value)
      .then(async data => {
        member.value = data;
      });
    };

    loadMember();

    const submitSuccessful = ref(false);
    const submitFailed = ref(false);

    const flirtCode = ref(null);

    const submitFlirt = () => {
        const message = {
          memberToDto: {
            name: member.value.name,
          },
          body: '',
          flirtDto: {
            code: flirtCode.value,
          },
        };
        submitMessageData(message);
    };

    const messageBody = ref('');

    const submitMessage = () => {
      const imageInput = document.querySelector('#image');
      const file = imageInput.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          const base64File = reader.result;
          const message = {
            memberToDto: {
              name: member.value.name,
            },
            body: messageBody.value,
            attachmentDto: {
              name: file.name,
              content: base64File
            }
          };
          submitMessageData(message);
        };
        imageInput.value = null;
      } else {
        const message = {
          memberToDto: {
            name: member.value.name,
          },
          body: messageBody.value,
        };
        submitMessageData(message);
      }
    };

    const submitMessageData = (message) => {
      sendMessage(message)
        .then(data => {
          messageBody.value = '';
          submitSuccessful.value = true;
          updateCredits(data.creditsLeft);
        })
        .catch(() => {
          submitFailed.value = true;
        });
    };

    const clearSuccessMessage = () => {
      submitSuccessful.value = false;
    };

    const clearFailureMessage = () => {
      submitFailed.value = false;
    };

    const addToWishes = () => {
      addToWishlist(member.value.name)
        .finally(() => {
          loadMember();
        })
    };

    const removeFromWishes = () => {
      removeFromWishlist(member.value.name)
        .finally(() => {
          loadMember();
        })
    };

    return {
      member,
      submitSuccessful,
      submitFailed,
      messageBody,
      flirtCode,
      submitFlirt,
      submitMessage,
      clearSuccessMessage,
      clearFailureMessage,
      addToWishes,
      removeFromWishes
    };
  },
};
</script>
